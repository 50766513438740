.hero-slider-container .flickity-page-dots {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 5vh !important;
    left: 50%;
    margin: auto;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 99999999;
}

.hero-slider-container .flickity-button {
    display: none !important;
}

.hero-slider-container .flickity-page-dots .dot {
    background: #FFF !important;
}

.hero-slider-container .flickity-page-dots .dot.is-selected {
    background: #FFF !important;
}