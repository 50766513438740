.hero-wrapper-432836 p p::first-letter {
    background-image: url(https://dev-olshop.berkatsoft.com/image/656564e27e608.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    float: right;
    background-color: #FFF;
    font-size: 35rem;
    line-height: 0.65;
    color: transparent;
    margin-left: 2rem;
    display: block;
}